import React, { useEffect, useState } from "react";
import '../sass/shared.scss';
import { getImageInformation } from "../data/imageRepository";
import { useParams } from "react-router-dom";
import ImageView from "../components/ImageView/imageView";
import BackgroundColor from "../components/common/BackgroundColor";
import Footer from "../components/shared/Footer";
import SwiperCarousel from "../components/carousel/swiperCarousel";


export default function GalleryPage() {
    const [defaultStyle, setDefaultStyle] = useState({});
    const [pageStyle, setStyle] = useState({});
    const [image, setImage] = useState(null);
    const [carouselImages, setCarouselImages] = useState([]);

    const { imageType, id } = useParams();

    useEffect(() => {
        const bodyStyles = window.getComputedStyle(document.body);
        setDefaultStyle({ backgroundColor: bodyStyles.backgroundColor, color: bodyStyles.color });
        const imageInfo = getImageInformation(imageType, id);
        setImage(imageInfo);
        if (imageInfo.associatedImages && imageInfo.associatedImages.length > 0) {
            setCarouselImages([imageInfo, ...imageInfo.associatedImages]);
        }
    }, [imageType, id]);

    const handleImageLoaded = (imageInformation) => {
        setStyle({
            backgroundColor: imageInformation.imageBackgroundColor ? imageInformation.imageBackgroundColor : defaultStyle.backgroundColor,
            color: imageInformation.textColor ? imageInformation.textColor : defaultStyle.color
        });
    }

    if (!image)
        return;

    return (
        <>
            {/* <Header headerText={image.title} /> */}
            <div className="gallery-container">
                {(!image.associatedImages || image.associatedImages.length === 0) && <ImageView imageInfo={image} handleImageLoaded={handleImageLoaded} />}
                {(image.associatedImages && image.associatedImages.length > 0) && <SwiperCarousel items={carouselImages} imageUpdated={handleImageLoaded} />}
            </div>
            <Footer />
            <BackgroundColor backgroundColor={pageStyle.backgroundColor}
                textColor={pageStyle.color} />
        </>
    );
}