import './App.scss';
import React from "react";
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Contact from "./containers/contact";
import GalleryPage from "./pages/galleryPage";
import MenuPage from "./pages/menuPage";
import LandingPage from "./pages/landingPage";
import PageTemplate from "./pages/pageTemplate";

function App() {

    return (
        <Router>
            <PageTemplate>
                <Routes>
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/galleryPage/:imageType/:id" element={<GalleryPage />} />
                    <Route path="/menuPage/:imageType" element={<MenuPage />} />
                </Routes>
            </PageTemplate>
        </Router>
    );
}

export default App;
