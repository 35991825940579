import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import ImageView from "../ImageView/imageView";

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './swiperCarousel.scss';

import './style.scss';

// import required modules
import { Pagination, Navigation } from 'swiper/modules';

const SwiperCarousel = (props) => {
    const [images, setImages] = useState(null);

  const handleImageLoaded = () => {
    // setLoaded(true);
    // props.imageUpdated(props.items[currentIndex]);
}

useEffect(() => {

  setImages(props.items);
}, [images]);

if (!images)
  return;

return (
    <>
      <Swiper
        centeredSlides={true}
        spaceBetween={0}
        slidesPerView={1}
        navigation={true}
        loop={true}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination, Navigation]}
      >
        {images.map((image) => (
          <SwiperSlide key={image.id}>
              <ImageView imageInfo={image} handleImageLoaded={handleImageLoaded} carouselOffset={1.25}/>
          </SwiperSlide>
        ))}        
      </Swiper>    
      
    </>
  );
};

export default SwiperCarousel;
