import {buildImageJson} from "./imageInformationFactory";

export const WATERCOLORS = "watercolors";
export const OILS = "oils";
export const RESTORATION = "restoration";
export const MAINMOSAICMENU = "mainMosaicMenu";
export const ABOUTME = "aboutme";

export const getMenuTitle = (type) => {
    const menus = {[WATERCOLORS]: "Watercolors", 
    [OILS]: "Oils", 
    [RESTORATION]: "Restoration", 
    [MAINMOSAICMENU]: "Susan J. Hopkins, Artist",
    [ABOUTME]: "About the Artist"
};

    return menus[type];
}

export const getImageInformation = (type, id) => {
    const imageJson = require(`../../public/images/${type}/${type}.json`);
    if (id) {
        const index = imageJson.images.findIndex(image => image.id === parseInt(id));
        return convertToImageInformation(type, imageJson, index);
    }

    let images = [];

    for (let imageIndex = 0; imageIndex < imageJson.images.length; imageIndex++) {
        try {
            images.push(convertToImageInformation(type, imageJson, imageIndex));
        } catch (e) {
            continue;
        }
    }
    
    images.sort((a, b) => a.id - b.id);
    return images;
};

const convertToImageInformation = (type, imageJson, imageIndex) => {
    const image = imageJson.images[imageIndex];
    const imageInfo = buildImageJson(type, image, imageJson["routeString"]);
    if (image["associatedImages"] && image["associatedImages"].length > 0) {
        imageInfo.associatedImages = image["associatedImages"].map(function (image) {
            return buildImageJson(type, image, imageJson["routeString"]);
        });
    }

    return imageInfo;
 }

export const getMosaicMenuInformation = (type) => {
    const imageJson = require(`/public/images/${type}/${type}.json`);

    let images = [];

    for (let i = 0; i < imageJson.length; i++) {
        try {
            images.push({
                type: "image",
                id: imageJson[i]["id"],
                title: imageJson[i]["title"],
                mosaic: `/images/${type}/mosaics/${imageJson[i]["mosaic"]}`,
                imageBackgroundColor: typeof imageJson[i]["imageBackgroundColor"] === undefined ? "" : imageJson[i]["imageBackgroundColor"],
                textColor: typeof imageJson[i]["textColor"] === undefined ? "" : imageJson[i]["textColor"],
                routeString: imageJson[i]["routeString"]
            });
        } catch (e) {
            continue;
        }
    }
    images.sort((a, b) => a.id - b.id);

    return images;
};