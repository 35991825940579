import React from "react";
import {Masonry, Tile} from "../components/masonry/masonry";
import {screenBreakPoints} from "../constants";
import '../sass/shared.scss';
import {useNavigate} from "react-router-dom";

export default function MosaicMenu({images}) {
    const navigate = useNavigate ();

    function onImageTileClick(source) {
        navigate(`/${source.routeString}`)
    }

    return (
        <>
            <Masonry screenBreakPoints={screenBreakPoints}>
                {images.map((image, id) => {
                    return (
                        <Tile key={id} source={image} id={id} onClick={onImageTileClick}/>
                    )
                })}
            </Masonry>

        </>

    );
}