import React, {useState} from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import '../sass/pageTemplate.scss';

export default function PageTemplate({ children }) {
    const [navBarShow, setNavbarShow] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();
    const isHomePage = location.pathname === "/";

    return (
        <div>
    <Navbar key={1} expand={false} className="navbar-bkground">
      <Container fluid>
        <Navbar.Brand href="/">
          {!isHomePage && <h1>Susan J. Hopkins, Artist</h1>}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${false}`} onClick={() => setNavbarShow(!navBarShow)} />
        <Navbar.Offcanvas
          show={navBarShow}
          id={`offcanvasNavbar-expand-${false}`}
          aria-labelledby={`offcanvasNavbarLabel-expand-${false}`}
          placement="end"
        >
          <Offcanvas.Header closeButton onClick={() => setNavbarShow(false)}>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${false}`}>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3">
              {!isHomePage && <Nav.Link onClick={() => {setNavbarShow(false); window.history.back();}}>Back</Nav.Link>}
              {!isHomePage && <hr />}
              {!isHomePage && <Nav.Link onClick={() => setNavbarShow(false)} href="/">Home</Nav.Link>}
              <Nav.Link onClick={() => setNavbarShow(false)} href="#/menuPage/oils">Oils</Nav.Link>
              <Nav.Link onClick={() => setNavbarShow(false)} href="#/menuPage/watercolors">Watercolors</Nav.Link>
              <Nav.Link onClick={() => setNavbarShow(false)} href="#/menuPage/acrylics">Acrylics</Nav.Link>
              <Nav.Link onClick={() => setNavbarShow(false)} href="#/menuPage/sacredart">Sacred Art</Nav.Link>
              <Nav.Link onClick={() => setNavbarShow(false)} href="#/menuPage/restoration">Restoration</Nav.Link>
              <Nav.Link onClick={() => setNavbarShow(false)} href="#/galleryPage/aboutme/1">About the Artist</Nav.Link>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
            <div>
                {children}
            </div>
        </div>
    );
}