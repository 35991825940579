import React from "react";
import { isNullOrEmpty } from "../common/utility";
import './header.scss';

export default function Header({ headerText }) {
    let h1Tag = <div className="mainHeader"><h1>{headerText}</h1></div>
    if (isNullOrEmpty(headerText))
    <div className="mainHeader"><h1>&nbsp;</h1></div>
    return (
        <header>
            {
                h1Tag
            }
        </header>
    )
}