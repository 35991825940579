import React, {useState, useEffect} from "react";
import Image from "./image";
import './imageview.scss';
import {isNullOrEmpty} from "../common/utility";
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from 'react-bootstrap/Button';
import Header from "../shared/Header";

const debounce = (func, wait) => {
    let timeout;
    return function (...args) {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), wait);
    };
};
export default function ImageView({imageInfo, handleImageLoaded, carouselOffset}) {

    const [imageDimensions, setImageDimensions] = useState({maxWidth: 0, maxHeight: 0});
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        const handleResize = debounce(() => {
            updateImageSize();
        }, 250);

        window.addEventListener('resize', handleResize);
        window.addEventListener("orientationchange", handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener("orientationchange", handleResize);
        }
    }, []);

    const imageLoaded = () => {
        updateImageSize();
    };

    const isLandscape = (natWidth, natHeight) => {
        let ratio = natWidth / natHeight;

        if (ratio > 1) return true;

        return false;
    }

    const getImageDimensions = () => {
        let image = document.getElementsByClassName("imageview-image");
        if (!image) return;

        let imageWidth = image[0].naturalWidth;
        let imageHeight = image[0].naturalHeight;
        return {imageWidth, imageHeight};
    }

    const updateImageSize = () => {
        const imageDimensions = getImageDimensions();
        if (!imageDimensions) return;

        window.scrollTo(0, 0);
        const viewPortWidth = window.innerWidth;

        let landscape = isLandscape(imageDimensions.imageWidth, imageDimensions.imageHeight);

        const viewPortHeight = window.innerHeight;
        let heightOffset = viewPortHeight * 0.23;

        let widthOffset = viewPortWidth * 0.15;
        if (viewPortWidth < 600 || viewPortHeight < 600) {
            widthOffset = viewPortWidth * 0.1;
        }

        if (viewPortHeight < 450) {
            if (landscape) {
                widthOffset = viewPortWidth * 0.53;
            } else {
                widthOffset = viewPortWidth * 0.77;
            }
        }

        if (carouselOffset && viewPortHeight > 450) {
            widthOffset = (carouselOffset + 1) * widthOffset;
        }

        setImageDimensions({
            maxWidth: viewPortWidth - widthOffset, maxHeight: viewPortHeight - heightOffset,
        });

        handleImageLoaded(imageInfo);
    }

    const hasText = !isNullOrEmpty(imageInfo.description);

    return (<div key={imageInfo.id} className="imageview-wrapper fadeIn">
            <Header headerText={imageInfo.title}/>
            <div className="imageview-image">
                <Image
                    imageInfo={imageInfo} handleImageLoaded={imageLoaded} style={{position: 'absolute'}}
                    maxDimensions={imageDimensions}/>
            </div>
            {hasText && <div className="imageview-button">
                <Button className="transparent-button" onClick={handleShow}>
                    Click here to see the description
                </Button>
            </div>}
            {!hasText && <div className="btn-placeholder">
            </div>}
            <Offcanvas show={show} onHide={handleClose} placement={'bottom'}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="artwork-title">{imageInfo.title}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <p className="artwork-description" dangerouslySetInnerHTML={{ __html: imageInfo.description }}>
                    </p>
                </Offcanvas.Body>
            </Offcanvas>

        </div>);
}