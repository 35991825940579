import React from 'react';

import '../index.scss';
import '../sass/pageTemplate.scss';
import '../sass/landingPage.scss';


const LandingPage = () => {
  return (
    <>
        <div className="backgroundImage"></div>
        <h2 className="site-title"><strong>Susan J Hopkins</strong></h2>
    </>

  );
};

export default LandingPage;
