import React, {useEffect, useState} from "react";
import {getImageInformation, getMenuTitle} from "../data/imageRepository";
import {useParams} from "react-router-dom";
import MosaicMenu from "../containers/mosaicMenu";
import Footer from "../components/shared/Footer";
import Header from "../components/shared/Header";
import '../sass/shared.scss';


export default function MenuPage() {
    const [images, setImages] = useState(null);
    const {imageType} = useParams();

    useEffect(() => {
        setImages(getImageInformation(imageType));
    }, [imageType])

    if(!images) return null;
    return (

        <>
            <Header headerText={getMenuTitle(imageType)}/>
            <MosaicMenu images={images}/>
            <Footer/>
        </>

    );
}