export const buildImageJson = (type, image, route) => {
    const imageInfo = {
        id: image["id"],
        type: "image",
        src: `/images/${type}/work/${image["file name"]}.jpg`,
        alt: image["title"],
        imageUrl: `/images/${type}/work/${image["file name"]}.jpg`,
        title: image["title"],
        mosaic: `/images/${type}/mosaics/${image["file name"]}.jpg`,
        description: image["description"],
        imageBackgroundColor: typeof image["imageBackgroundColor"] === undefined ? "" : image["imageBackgroundColor"],
        textColor: typeof image["textColor"] === undefined ? "" : image["textColor"],
        tileTextColor: typeof image["tileTextColor"] === undefined ? "" : image["tileTextColor"],
        routeString: `${route}/${type}/${image["id"]}`,
        associatedImages:null
    };

    return imageInfo;
}