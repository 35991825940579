import React, { useEffect } from "react";

const BodyBackground = ({ backgroundColor, textColor }) => {
    useEffect(() => {
        document.body.style.backgroundColor = backgroundColor;
        document.body.style.color = textColor;

        let elements = Array.from(document.getElementsByTagName("button"));
        elements = elements.concat(Array.from(document.getElementsByTagName("header")));
        elements = elements.concat(Array.from(document.getElementsByTagName("footer")));
        const circles = Array.from(document.getElementsByClassName("circle"));
        const xElements = document.querySelectorAll(".x, .x::before, .x::after");

        circles.forEach(circle => {
            circle.style.borderColor = textColor;
        });

        xElements.forEach(xes => {
            xes.style.background = textColor;
        });

        if(elements)
        {
            elements.map((element) => {
               element.style.background = backgroundColor;
               element.style.color = textColor;
               return element;
            });
        }
        return () => {
            document.body.style.backgroundColor = "";
            document.body.style.color = "";
            const elements = Array.from(document.getElementsByTagName("button"));
            if(elements)
            {
                elements.map((element) => {
                    element.style.background = "";
                    element.style.color = "";
                });
            }
        };
    }, [backgroundColor, textColor]);

    return;
};

export default BodyBackground;