import React from "react";
import './footer.scss';

export default function Footer() {

    return (
        <footer>
            <div className='footer'>
                Copyright &#169;2022 Susan J Hopkins all rights reserved.
            </div>
        </footer>
    )
}