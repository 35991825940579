import React, {useState} from "react";
import './masonry.scss';

export function Tile({source, id, onClick}) {
    const handleClick = () => {
        onClick(source);
    }
    return (
        <div className="tile" onClick={handleClick}>
            {/* <div className="picture-info">
                <h2 style={{ color: source.tileTextColor }}>{source.title}</h2>
            </div> */}
            <img src={source.mosaic} key={id} className="title-image" alt={source.title}/>
            <h2 className="title">{source.title}</h2>
        </div>
    );
};

export function Masonry({ children, screenBreakPoints}) {

    const getColumns = () => {
        const screenWidth = window.innerWidth;

        for (let i = 0; i < screenBreakPoints.length; i++) {
            const breakpoint = screenBreakPoints[i];

            if (screenWidth > breakpoint.min && screenWidth < breakpoint.max) {
                return breakpoint.columns;
            }
        }

        return 4;
    };

    const [columns, setColumns] = useState(getColumns);
    
    const onResize = () => {
        //const element = document.getElementById("masonry");
        const numColumns = getColumns();

        console.log("numColumns", numColumns);

        if (numColumns !== columns) {
            setColumns(numColumns);
        }
    }

    const mapChildren = () => {
        let col = [];
        const numC = columns;
        for (let i = 0; i < numC; i++) {
            col.push([]);
        }
        return children.reduce((accumulator, child, index) => {
            accumulator[index % numC].push(child);
            return accumulator;
        }, col);
    }

    React.useEffect(() => {
        window.addEventListener("resize", onResize);
        return () => window.removeEventListener("resize", onResize);
    });
    
    return (
        <div className="masonry-container">
        <div className="masonry" id="masonry">
            {mapChildren().map((col, ci) => {
                return (
                    <div className="column" key={ci}>
                        {col.map((child, i) => {
                            return <div key={i} id={i} >{child}</div>
                        })}
                    </div>
                )
            })}
        </div>
        </div>
    )
}
