import React from "react";
import './image.scss';

export default function Image({imageInfo, handleImageLoaded, maxDimensions})
{
    const viewPortHeight = window.innerHeight;
    let heightOffset = viewPortHeight * 0.23;
    const maxHeight = viewPortHeight - heightOffset;
    
    return (
        <>
            <img
                className="imageview-image"
                src={imageInfo.src}
                alt={imageInfo.title}
                onLoad={handleImageLoaded}
                style={{maxHeight: maxDimensions.maxHeight, maxWidth: maxDimensions.maxWidth}}
            />
        </>
    );
}